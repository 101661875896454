function initialize() {
  document.getElementsByTagName("form")[0].action +=
    document.location.search || "?ReturnUrl=%2f";

  let userElem = document.querySelector<HTMLInputElement>("#username");
  userElem && (userElem.placeholder = "Telefonnummer");
  let pwdElem = document.querySelector<HTMLInputElement>("#password");
  pwdElem && (pwdElem.placeholder = "Passord");

  const rememberMe = document.querySelector("#remember");
  rememberMe &&
    (rememberMe.innerHTML = rememberMe.innerHTML.replace(
      "Keep me logged in",
      "Husk meg"
    ));

  document.querySelectorAll(".login-box__actions .btn").forEach((el, i) => {
    el.innerHTML = el.innerHTML.replace("Log in", "Logg inn");
    el.innerHTML = el.innerHTML.replace("Reset Password", "Glemt passord");
  });

  let asteriskIcon = document.querySelector(".glyphicon-asterisk");
  asteriskIcon &&
    (asteriskIcon.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M7,14A2,2 0 0,1 5,12A2,2 0 0,1 7,10A2,2 0 0,1 9,12A2,2 0 0,1 7,14M12.65,10C11.83,7.67 9.61,6 7,6A6,6 0 0,0 1,12A6,6 0 0,0 7,18C9.61,18 11.83,16.33 12.65,14H17V18H21V14H23V10H12.65Z" /></svg>`);

  let userIcon = document.querySelector(".glyphicon-user");
  userIcon &&
    (userIcon.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" /></svg>`);

  let phoneIcon = document.querySelector(".glyphicon-phone");
  phoneIcon &&
    (phoneIcon.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M17.25,18H6.75V4H17.25M14,21H10V20H14M16,1H8A3,3 0 0,0 5,4V20A3,3 0 0,0 8,23H16A3,3 0 0,0 19,20V4A3,3 0 0,0 16,1Z" /></svg>`);

  let mailIcon = document.querySelector(".glyphicon-inbox");
  mailIcon &&
    (mailIcon.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" /></svg>`);

  document
    .querySelectorAll(".glyphicon")
    .forEach((el) => (el.className = "input-icon"));
  document.querySelector<HTMLElement>("input:not([disabled])")?.focus();
}

document.addEventListener("DOMContentLoaded", initialize);
